import React, { useEffect, useState } from 'react';
import { Outlet, Link,  useParams, useNavigate } from "react-router-dom";
import ContentHeader from '../../parts/ContentHeader';
import MainContainer from '../../parts/MainContainer';
import InputText from '../../elements/InputText'
import InputTextArea from '../../elements/InputTextArea'
import DropDownSelection from '../../elements/DropDownSelection';
import Radio from '../../elements/InputRadio';
import InputFile from '../../elements/InputFile';
import Checkbox from '../../elements/InputCheckbox';
import Loader from '../../parts/Loader';
import InputNumber from '../../elements/InputNumber';
import InputRadio from '../../elements/InputRadio';
import RenderErrors from '../../elements/RenderErrors';
import store from '../../../helpers/store';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders, httpFormRequest } from '../../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
 
function ServiceRequestForm ({readonly = false}){
    let { service_id } = useParams();
    const [form_data, setForm] = useState(store.form_data);
    const [form_title, SetTitle] = useState('');
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    const [status_badge, setStatusBadge] = useState([]);
    const [return_url, setReturnURL] = useState('/service-request');
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);
    const [image_preview, setImagePreview] = useState(false);
    const [branch_list, setBranches] = useState(store.branch_data);

    useEffect(() => {
        //Mounted
        if(service_id){
            getData(service_id)
            SetTitle('Update Service Request: ')
        }
        else{
            SetTitle('Create New Service Request')
        }
        
        if(helpers.checkPermissions(["all-access"])){
            setReturnURL('/service-request')
        }
        else if(helpers.checkPermissions(["technician-dashboard"])){
            setReturnURL('/technician-service-request')
        }

        getBranches();
    }, []);

    function getData(service_id){
        const url = helpers.getUrl('admin/service-request/'+service_id)
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                const form_key =[
                    'first_name', 
                    'last_name', 
                    'mobile_no', 
                    'email', 
                    'address_1',
                    'address_2',
                    'city',
                    'province',
                    'appliance_type',
                    'complaint',
                    'brand',
                    'model_no',
                    'warranty_status',
                    'serial_no',
                    'purchased_date', 
                    'location',
                    //'proof_of_purchase',
                    'proof_of_purchase_url',
                    //'unit_details',
                    'unit_details_url',
                    //'unit_defect',
                    'unit_defect_url',
                    'branch_id'
                ]

                
                const filtered = Object.keys(res.data)
                                .filter(key => form_key.includes(key))
                                .reduce((obj, key) => {
                                obj[key] = res.data[key];
                                return obj;
                                }, {});

                setForm({ ...form_data, ...filtered })
                SetTitle('Service Request: '+ res.data.service_no)
                setLoading(0)

                setStatusBadge(helpers.displayStatusBadge(res.data.status))
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function getBranches(){
        const url = helpers.getUrl('admin/branches')
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                //let branchs = [...branch_list]
                //setBranches([...branchs, ...res.data])
                setBranches(res.data)

                setLoading(0)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    //title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setForm({ ...form_data, ...new_state });
    }

    function getFormData(status = 'draft'){
        let form = new FormData();

        for(var key in form_data) {
            const val = form_data[key] ?? ''
            form.append(key, form_data[key]);
        }

        return form
    }

    function getFileName(key = ''){
        let filename = ''
        if(form_data[key]){
            filename = form_data[key].name
        }

        return filename
    }

    function displayImage(url = '', alt =''){
        if(url && service_id){
            return <>
                    <img src={url} width={100} className='img-thumbnail' alt={alt} onClick={(e) => handleShow('show', url, alt)} style={{cursor: 'pointer'}}/><br/><small>Preview</small>
                </>
        }
    }

    function handleShow(action = 'show', img_url = '', img_alt = ''){
        setImagePreview( 
            <Modal size="xl" show={1} onHide={handleClose}>
                <Modal.Body>
                    <img src={img_url} className='img-fluid' alt={img_alt}/>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
    function handleClose(){
        setImagePreview('')
    }


    function submit(e){
        const formData = getFormData()
        const warranty_status = form_data.warranty_status;

        let url = helpers.getUrl('admin/service-request')

        if(service_id){
            url = helpers.getUrl('admin/service-request/'+service_id)
        }

        setLoading(1)

        httpFormRequest(url, "POST", {
            postdata: formData,
        }, true)
            .then((res) => {
                setLoading(0)

                let msg = res.message

                if(warranty_status == 'active'){
                    msg = 'Please ready proof of purchase such as receipt to expedite in-warranty processing.'
                }

                Swal.fire({
                    title: "Success",
                    text: msg,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        return navigate("/service-request");
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }
                
                // //Unauthorize
                // if(err.status_code === 401){
                //     window.location.href = "/";
                // }

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function isWarrantyCheck(status = ''){
        if(status == 'active') return true;

        return false;
    }
    
    return (
        <MainContainer page_title={form_title} menu="service-request-main">
            {image_preview}
            <div className="container-fluid">
                <div className="row d-flex justify-content-center" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="col-sm-10 col-md-8">
                        <form className="card">
                            <div className="card-body">
                                    <div className="row">
                                        <div className="col-8 mb-3">
                                            <p className='mb-0'>Personal Information</p>
                                        </div>
                                        <div className="col-4 mb-3 text-right">{status_badge}</div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="first_name" 
                                                name="first_name" 
                                                value={form_data.first_name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="First Name"
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> First Name</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="first_name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="last_name" 
                                                name="last_name" 
                                                value={form_data.last_name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Last Name"
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> Last Name</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="last_name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputNumber
                                                id="mobile_no" 
                                                name="mobile_no" 
                                                value={form_data.mobile_no}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Mobile  No"
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> Mobile No</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="mobile_no" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="email" 
                                                name="email" 
                                                value={form_data.email}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Email"
                                                type="email"
                                                label="Email"
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="email" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <InputTextArea
                                                id="address_1" 
                                                name="address_1" 
                                                label={<><span className='text-danger fw-bold'>*</span> Address Line 1</>}
                                                value={form_data.address_1}  
                                                tagClass="form-control"
                                                onChange={inputChange}
                                                placeholder="Apartment, House No etc."
                                                rows={1}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="address_1" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <InputTextArea
                                                id="address_2" 
                                                name="address_2" 
                                                label="Address Line 2"
                                                value={form_data.address_2}  
                                                tagClass="form-control"
                                                onChange={inputChange}
                                                placeholder="Street No, Barangay, District"
                                                rows={1}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="address_2" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="city" 
                                                name="city" 
                                                value={form_data.city}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Municipality/City"
                                                type="city"
                                                label={<><span className='text-danger fw-bold'>*</span> City</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="city" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="province" 
                                                name="province" 
                                                value={form_data.province}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Province"
                                                type="province"
                                                label={<><span className='text-danger fw-bold'>*</span> Province</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="province" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-12"><hr /></div>
                                        <div className="col-12 form-group">
                                            <div className='row border' style={{padding: '0.5rem'}}>
                                                <div className="col-sm-6 col-md-2 mb-0 form-group">
                                                    <div className="form-check" style={{ paddingLeft: '0'}}>
                                                    <InputRadio
                                                        id="warranty_status_1"
                                                        name="warranty_status" 
                                                        value="active" 
                                                        checked = {form_data.warranty_status == 'active'}
                                                        label="In-Warranty"
                                                        tagClass="form-control" 
                                                        onChange={inputChange}
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-2 form-group mb-0">
                                                    <div className="form-check" style={{ paddingLeft: '0'}}>
                                                    <InputRadio
                                                        id="warranty_status_2"
                                                        name="warranty_status" 
                                                        value="expired" 
                                                        checked = {form_data.warranty_status == 'expired'}
                                                        label="Out-Warranty"
                                                        tagClass="form-control" 
                                                        onChange={inputChange}
                                                    />
                                                    </div>

                                                </div>

                                            </div>
                                            <RenderErrors 
                                                errors={errors} 
                                                name="warranty_status" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-12" />
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="appliance_type" 
                                                name="appliance_type" 
                                                value={form_data.appliance_type}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="TV, Refregerator, Aircondition etc."
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> Application Type</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="appliance_type" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="brand" 
                                                name="brand" 
                                                value={form_data.brand}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Brand"
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> Brand</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="brand" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="model_no" 
                                                name="model_no" 
                                                value={form_data.model_no}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Unit Model or Part No."
                                                type="text"
                                                label="Model Number"
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="model_no" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="serial_no" 
                                                name="serial_no" 
                                                value={form_data.serial_no}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Unit Serial No."
                                                type="text"
                                                label="Serial Number"
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="serial_no" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="purchased_date" 
                                                name="purchased_date" 
                                                value={form_data.purchased_date}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Date"
                                                type="date"
                                                label="Date of Purchased"
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="purchased_date" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="location" 
                                                name="location" 
                                                tagClass="form-control mb-0"
                                                value={form_data.location}  
                                                onChange={inputChange}
                                                placeholder="Location"
                                                type="text"
                                                label="Unit Location"
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="location" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <DropDownSelection
                                                tagClass="form-control"
                                                name='branch_id' 
                                                placeholder='Select Branch'
                                                label={<>Branch <span className="text-danger">*</span></>}
                                                value={form_data.branch_id}  
                                                onChange={inputChange}
                                                optionList={branch_list}
                                                optionProps ={{value:'id', label:'name'}}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="branch_id" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <InputTextArea
                                                id="complaint" 
                                                name="complaint" 
                                                label={<><span className='text-danger fw-bold'>*</span> Complaint</>}
                                                value={form_data.complaint}  
                                                tagClass="form-control"
                                                onChange={inputChange}
                                                placeholder="Describe unit problem"
                                                rows={2}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="complaint" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>

                                        {
                                            form_data.warranty_status=='active'
                                            ? 
                                            <div className="col-md-6 form-group">
                                                {
                                                    !readonly ? 
                                                    <>
                                                    <InputFile
                                                        id="proof_of_purchase" 
                                                        name="proof_of_purchase" 
                                                        tagClass="form-control"
                                                        label={<><span className='text-danger fw-bold'>*</span> Proof of Purchase</>}
                                                        onChange={inputChange}
                                                        type="file"
                                                        accept="image/*"
                                                    />
                                                    <p className='mb-0'>{getFileName('proof_of_purchase')}</p>
                                                    </> : 
                                                    <><label>Proof of Purchase</label><br/></>
                                                }
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="proof_of_purchase" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                                {displayImage(form_data.proof_of_purchase_url, 'Proof of Purchased')}
                                            </div>
                                            : ''
                                        }

                                        {
                                            form_data.warranty_status=='active'
                                            ? 
                                            <div className="col-md-6 form-group">
                                                {
                                                    !readonly ? 
                                                    <>
                                                    <InputFile
                                                        id="unit_details" 
                                                        name="unit_details" 
                                                        tagClass="form-control"
                                                        label={<><span className='text-danger fw-bold'>*</span> Unit Details</>}
                                                        onChange={inputChange}
                                                        type="file"
                                                        accept="image/*"
                                                    />
                                                    <p className='mb-0'>{getFileName('unit_details')}</p>
                                                    </> : 
                                                    <><label>Unit Details</label><br/></>
                                                }
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="unit_details" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                                {displayImage(form_data.unit_details_url, 'Unit Details')}
                                            </div>
                                            : ''
                                        }

                                        {
                                            form_data.warranty_status=='active'
                                            ? 
                                            <div className="col-md-6 form-group">
                                                {
                                                    !readonly ? 
                                                    <>
                                                        <InputFile
                                                            id="unit_defect" 
                                                            name="unit_defect" 
                                                            tagClass="form-control"
                                                            label={<><span className='text-danger fw-bold'>*</span> Unit Defective</>}
                                                            onChange={inputChange}
                                                            type="file"
                                                            accept="image/*"
                                                        />
                                                        <p className='mb-0'>{getFileName('unit_defect')}</p>
                                                    </> : 
                                                    <><label>Unit Defective</label><br/></>
                                                }
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="unit_defect" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                                {displayImage(form_data.unit_defect_url, 'Unit Defect')}
                                            </div>
                                            : ''
                                        }
                                    </div>
                                
                            </div>
                            <div className="card-footer text-right">                       
                                {
                                    !readonly ?
                                    <>
                                    <Link className='mr-3' to={return_url}><i className="fas fa-arrow-left mr-3" />Cancel</Link>
                                    <button type='button' className='btn btn-success btn-sm' onClick={submit}><i className="fas fa-save mr-2" />{service_id ? 'Update' : 'Save'}</button>
                                    </>
                                    :
                                    <Link className='mr-3' to={return_url}><i className="fas fa-arrow-left mr-3" />Back</Link>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}
 
export default ServiceRequestForm;