const form_data = {
      'first_name': '', 
      'last_name': '', 
      'mobile_no': '', 
      'email': '', 
      'address_1': '',
      'address_2': '',
      'city': '',
      'province': '',
      'appliance_type': '',
      'complaint': '',
      'brand': '',
      'model_no': '',
      'warranty_status': '',
      'serial_no': '',
      'purchased_date': '', 
      'location': '',
      'proof_of_purchase': '',
      'proof_of_purchase_url':'',
      'unit_details': '',
      'unit_details_url':'',
      'unit_defect': '',
      'unit_defect_url':'',
      'branch_id': ''
}

const service_schedule = {
      'technician_id': '', 
      'extra_info': '', 
      'schedule_type': 'on-site', 
      'schedule_date': '',
      'assessment': '', 
      'job_description': '', 
      'assessed_date': '', 
      'dealer': '',
      'labor_fee': ''
}

const user_data = {
   'employee_id': '', 
   'name': '', 
   'email': '', 
   'password': '',
   'confirm_password': '',
   'status': 1,
   'role_id': ''
}

const employee_data = {
   'branch_id': 1, 
   'first_name': '', 
   'last_name': '', 
   'position': '',
   'status': 1,
}

const gender = [
   {'id': 'male', 'label': 'Male'},
   {'id': 'female', 'label': 'Female'}
]

const warranty_status = [
   {'id': '', 'name': '--- Any Warranty ---'},
   {'id': 'active', 'name': 'In-Warranty'},
   {'id': 'expired', 'name': 'Out-Warranty'},
]
const service_status = [
   {'id': '', 'name': '--- Any Status ---'},
   {'id': 'created', 'name': 'New Request'},
   {'id': 'for-assessment', 'name': 'For Assessment'},
   {'id': 'unit-checked', 'name': 'Unit Checked'},
   {'id': 'in-progress', 'name': 'In Progress'},
   {'id': 'repair-completed', 'name': 'Repair Completed'},
   {'id': 'for-release', 'name': 'Ready For Release'},
   {'id': 'completed', 'name': 'Completed'},
   {'id': 'return-to-owner', 'name': 'Return-to-owner'},
   {'id': 'cancelled', 'name': 'Cancelled'},
   {'id': 'available', 'name': 'Parts Status - Available'},
   {'id': 'pending-down-payment', 'name': 'Parts Status - Pending Down Payment'},
   {'id': 'ordered', 'name': 'Parts Status - Ordered'},
   {'id': 'received', 'name': 'Parts Status - Received'},
   {'id': 'price-inquiry', 'name': 'Parts Status - Price Inquiry'}
]

const per_page = 15;

const branch_data = [
   // {
   //    address: "",
   //    created_at: "",
   //    id: '',
   //    name: "Any Branch",
   // }
]

const store = {
   form_data,
   employee_data,
   service_schedule,
   user_data,
   service_status,
   branch_data,
   warranty_status,
   per_page
}

export default store