import React from 'react';
import { Outlet, Link } from "react-router-dom";
import images from '../../helpers/images';

import appSession from '../../helpers/session';

import helpers from '../../helpers/helpers';

function Header({menu}){

    function setCurrentPage(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'nav-link'

        if(menu_id.includes(current_page)){
            classes += ' active '

            if(parent_menu){
                classes += 'bg-info'
            }
            else{
                classes += 'bg-secondary'
            }
        }

        return classes;
    }

    function setMenuOpen(menu_id = [], current_page = ''){
        let classes = 'nav-item'

        if(menu_id.includes(current_page)){
            classes += ' menu-open'
        }
        return classes;
    }

    return (
        <>

            {/* Navbar */}
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                    </li>
                </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                <li className={menu == 'profile' ? "nav-item active text-danger" : "nav-item"}>
                    <Link className="nav-link" to="/profile"><i className="fas fa-user mr-2" /> {helpers.getUserName()}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" data-widget="control-sidebar" data-slide="true" onClick={() => appSession.logout()}><i className="fas fa-sign-out-alt"></i> Logout</Link>
                </li>
            </ul>
            </nav>

            {/* Main Sidebar Container */}
            <aside className="main-sidebar sidebar-light-primary elevation-4">
            {/* Brand Logo */}
            <Link className="brand-link text-center" to="/">
                {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} /> */}
                <span className="brand-text font-weight-light"><img src={images.mainLogo} alt="Logo" width={200} /></span>
            </Link>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar user panel (optional) */}
                    {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                        </div>
                        <div className="info">
                            <Link className="d-block" to="/">{helpers.getUserName()}</Link>
                        </div>
                    </div> */}
                    {/* SidebarSearch Form */}
                    {/* <div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw" />
                            </button>
                            </div>
                        </div>
                    </div> */}
                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link className={setCurrentPage(['home'], menu, true)} to="/">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>Dashboard</p>
                            </Link>
                        </li>
                        {
                            helpers.checkPermissions(["all-access", "service-request-dashboard"]) ? 
                            <li className="nav-item">
                                <Link className={setCurrentPage(['service-request-main'], menu, true)} to="/service-request">
                                    <i className="nav-icon fas fa-tasks" />
                                    <p>Service Request</p>
                                </Link>
                            </li> : ""
                        }
                        {
                            helpers.checkPermissions(["all-access", "technician-dashboard"]) ? 
                            <li className="nav-item">
                                <Link className={setCurrentPage(['service-request-technician'], menu, true)} to="/technician-service-request">
                                    <i className="nav-icon fas fa-tools" />
                                    <p>Technician Page</p>
                                </Link>
                            </li> : ""
                        }
                        {
                            helpers.checkPermissions(["all-access", "manage-users"]) ? 
                            <li className="nav-item">
                                <Link className={setCurrentPage(['manage-users'], menu, true)} to="/manage/user-account">
                                    <i className="nav-icon fas fa-user" />
                                    <p>User Account</p>
                                </Link>
                            </li> : ""
                        }
                        {
                            helpers.checkPermissions(["all-access", "manage-employees"]) ? 
                            <li className="nav-item">
                                <Link className={setCurrentPage(['manage-employees'], menu, true)} to="/manage/employees">
                                    <i className="nav-icon fas fa-users" />
                                    <p>Employees</p>
                                </Link>
                            </li> : ""
                        }
                        {/* <li className={setMenuOpen(['manage-users', 'manage-employees'], menu)}>
                            <a href="#" className={setCurrentPage(['manage-users', 'manage-employees'], menu, true)} >
                                <i className="nav-icon fas fa-tools"></i>
                                <p>Management <i className="right fas fa-angle-left" /></p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link className={setCurrentPage(['manage-users'], menu)} to="/manage/user">
                                        <i className="fas fa-caret-right nav-icon" />
                                        <p>User Account</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={setCurrentPage(['manage-employees'], menu)} to="/manage/employee">
                                        <i className="fas fa-caret-right nav-icon" />
                                        <p>Employees</p>
                                    </Link>
                                </li>
                            </ul>
                        </li> */}
                        {/* <li className={setMenuOpen(['reports-serivce-request', 'reports-job-order'], menu)}>
                            <a href="#" className={setCurrentPage(['reports-serivce-request', 'reports-job-order'], menu, true)}>
                                <i className="nav-icon fas fa-chart-pie"></i>
                                <p>Reports <i className="right fas fa-angle-left" /></p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link className={setCurrentPage(['reports-serivce-request'], menu)} to="/reports/service-request">
                                    <i className="fas fa-caret-right nav-icon" />
                                        <p>Service Request</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={setCurrentPage(['reports-job-order'], menu)} to="/reports/job-order">
                                    <i className="fas fa-caret-right nav-icon" />
                                        <p>Job Order</p>
                                    </Link>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>




            <Outlet />
        </>
    )
}

export default Header;