import React, { useEffect, useState } from 'react';
import { Outlet, Link,  useParams  } from "react-router-dom";
import ContentHeader from '../../parts/ContentHeader';
import MainContainer from '../../parts/MainContainer';
import Swal from 'sweetalert2';
import helpers from '../../../helpers/helpers';
import store from '../../../helpers/store';
import { httpRequest } from '../../../helpers/httpService';
import images from '../../../helpers/images';
import moment from 'moment/moment';
import { act } from 'react-dom/test-utils';
 
function JobOrderInfo ({service_id, action='', className=''}){
    //let { service_id } = useParams();
    const [service_data, setData] = useState({
        'first_name': '', 
        'last_name': '', 
        'mobile_no': '', 
        'email': '', 
        'address_1': '',
        'address_2': '',
        'city': '',
        'province': '',
        'appliance_type': '',
        'complaint': '',
        'brand': '',
        'model_no': '',
        'warranty_status': '',
        'serial_no': '',
        'purchased_date': '', 
        'location': '',
        'proof_of_purchase': '',
        'proof_of_purchase_url': '',
        'unit_details': '',
        'unit_details_url': '',
        'unit_defect': '',
        'unit_defect_url': '', 
        'required_parts': '',
        'dealer':'',
        'labor_fee': ''
    });
    const [form_title, SetTitle] = useState('');
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    const [status_badge, setStatusBadge] = useState([]);
    const [required_parts, setRequiredParts] = useState([]);
    const [sub_total, setSubTotal] = useState(0);
    const [total_amount, setTotal] = useState(0);
    const [labor_fee, setLaborFee] = useState(0);
    const [load_status, setStatus] = useState(0);

    useEffect(() => {
        //Mounted
        if(service_id){
            getData(service_id)
        }
    }, []);

    useEffect(() => {
        if(service_data.hasOwnProperty("required_parts")){
            const parts = service_data.required_parts

            let table_items = [];
            let total = 0;
            if (Object.keys(parts).length > 0) {
                table_items = Object.entries(parts).map(([key, data]) => {
                    const unit_price = Number(data.unit_price)
                    const sub_total = Number(data.unit_price) * Number(data.quantity)
                    total += sub_total;

                    return (<tr key={data.id}>
                                    <td>{data.item_description}</td>
                                    <td>{data.quantity}</td>
                                    <td>&#8369;&nbsp;{helpers.getFormatAmount(data.unit_price)}</td>
                                    <td>&#8369;&nbsp;{helpers.getFormatAmount(sub_total)}</td>
                                    <td>{helpers.ucwords(data.status)}</td>
                            </tr>)
                })
            }
            else{
                table_items =<tr key="0">
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                            </tr>

            }

            total += Number(service_data.labor_fee);
            setTotal(total)

            setRequiredParts(table_items)
            setSubTotal(total)

            setStatus(1)
        }
    }, [service_data]);

    useEffect(() => {
        if(load_status && action === 'print'){
            setTimeout(function(){
                window.print()
            }, 2000);
        }
    }, [load_status]);

    function getData(service_id){
        const url = helpers.getUrl('admin/service-request/'+service_id)
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setData(res.data)

                setLoading(0)
                // setStatusBadge(helpers.displayStatusBadge(res.data.status))
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function print(){
        window.print()
    }
    //{load_status ? window.addEventListener("load", window.print()) : ''}

    return (
        <>
        <section className={className}>
                <div className="row">
                    <div className="col-12">
                        {/* Main content */}
                        <div className="invoice p-3">
                        {/* title row */}
                        <div className="row mb-3">
                            <div className="col-12">
                            <h5>
                                <img src={images.mainLogo} alt="Logo" width={250} />
                                <small className="float-right">Date: {moment().format('MMM D, YYYY')}</small>
                            </h5>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* info row */}
                        <div className="row invoice-info">
                            <div className="col-sm-4 invoice-col">
                            From
                            <address>
                                <strong>JMA Electronics</strong><br />
                                Dadiangas South Marang St. Cor. Rosas<br />
                                General Santos City, South Cotabato, PH 9500<br />
                                Phone: (804) 553-1481<br/>
                                Mobile: 09213202476
                            </address>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-4 invoice-col">
                            To
                            <address>
                                <strong>{service_data.first_name} {service_data.last_name}</strong><br />
                                {service_data.address_1}<br />
                                {service_data.address_2 ? <>{service_data.address_2} <br /></> : ''}
                                {service_data.city}, {service_data.province}<br />
                                Mobile: {service_data.mobile_no}<br/>
                                {service_data.email ? 'Email: ' + service_data.email : ''}
                                
                            </address>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-4 invoice-col">
                            <b>Service Request No:</b> {service_data.service_no}<br />
                            <b>Techinician:</b> {service_data.technician_first_name ? service_data.technician_first_name + ' ' + service_data.technician_last_name : '---'}<br />
                            <b>Appliance Type:</b> {service_data.appliance_type}<br />
                            <b>Brand:</b> {service_data.brand ? service_data.brand : '---'}<br />
                            <b>Model No:</b> {service_data.model_no ? service_data.model_no : '---'}<br />
                            <b>Serial No:</b> {service_data.serial_no ? service_data.serial_no : '---'}<br />
                            <b>Warranty Status:</b> {helpers.ucwords(service_data.warranty_status)}<br />
                            <b>Complaint:</b> {service_data.complaint}<br />
                            <b>Schedule Type:</b> {service_data.schedule_type ? helpers.ucwords(service_data.schedule_type) : '---'}<br />
                            {
                                service_data.dealer ?
                                <><b>Dealer:</b> {service_data.dealer}<br /></> : ''
                            }
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                        {/* Table row */}
                        {/* <div className="row mt-4">
                            <div className="col-12">
                                <h4 className='text-center'>Repair Service Order</h4>
                            </div>
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-12">Repair Description: <span style={{textDecoration: 'underline'}}>{service_data.job_description}</span></div>
                        </div>
                        <div className="row">
                            <div className="col-12 table-responsive">
                                <table className="table table-sm table-striped print-table-border mb-0">
                                    <thead>
                                    <tr>
                                        <th>Item Description</th>
                                        <th width="50">Quantity</th>
                                        <th width="150">Unit&nbsp;Amount</th>
                                        <th width="150">Total</th>
                                        <th width="200">Parts&nbsp;Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {required_parts}
                                    </tbody>
                                    <tfoot className='print-border-bottoms'>
                                        <tr>
                                            <td colSpan={3} className='text-right'  style={{fontWeight: 'bold'}}><b>LABOR FEE</b></td>
                                            <td style={{fontWeight: 'bold'}} colSpan={2}>&#8369; {helpers.getFormatAmount(service_data.labor_fee)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className='text-right border-bottoms'  style={{fontWeight: 'bold'}}><b>TOTAL AMOUNT</b></td>
                                            <td className='border-bottoms' style={{fontWeight: 'bold'}} colSpan={2}>&#8369; {helpers.getFormatAmount(total_amount)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                        <div className="row">
                            {/* accepted payments column */}
                            {/* <div className="col-6 col-md-8 mb-3">
                                <p className="lead">Job Description:</p>
                                <p className="text-muted well well-sm shadow-none" style={{marginTop: 10}}>{service_data.job_description}</p>
                                <p className="lead mb-0">Unit Assessment:</p>
                                <p className="text-muted well well-sm shadow-none" style={{marginTop: 10}}>{service_data.assessment}</p>
                            </div> */}
                            {/* /.col */}
                            {/* <div className="col-6 col-md-4">
                                <div className="table-responsive">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th style={{width: '50%'}}>Subtotal:</th>
                                                <td>PHP {helpers.getFormatAmount(sub_total)}</td>
                                            </tr>
                                            <tr>
                                                <th>Labor Fee</th>
                                                <td>PHP {helpers.getFormatAmount(service_data.labor_fee)}</td>
                                            </tr>
                                            <tr>
                                                <th>Total:</th>
                                                <td>PHP {helpers.getFormatAmount(total_amount)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> */}
                            {/* /.col */}
                        </div>
                        <div className="row mt-3">
                            <div className="col-4">
                                <div className="cards">
                                    <table className='table border-0'>
                                        <tr>
                                            <td className='border-top-0'>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className='text-center print-border-top'>Technician’s Signature Over Printed Name</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-3">
                                <div className="cards">
                                    <table className='table table-large'>
                                        <tr>
                                            <td className='border-top-0'>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className='text-center print-border-top'>Date</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" style={{fontSize: '12px'}}>
                                <strong>Terms and Conditions:</strong> <br/>
                                <strong>Fees:</strong> The customer agrees to pay for all parts and labor as outlined in the Repairs Details Above.<br/>
                                <strong>Right to Refuse Repair:</strong> JMA ELectronics reserves the right to refuse service to any customer at its sole discretion. <br/>
                                <strong>Changes to Repair Cost:</strong> JMA Electronics reserves the right to change the price of parts and labor as necessary and will provide the customer with a revised estimate if this occurs. <br/>
                                <strong>Cancellation Fee:</strong> If the customer cancels the repair order after JMA Electronics has ordered parts for the repair work, customer agrees to pay cancellation fee equivalent to 30% of the parts and any other expenses incurred by the JMA Electronics in connection with the repair work. <br/>
                                <strong>Liability:</strong> The customer agrees to indemnify and hold JMA Electronics harmless from any and all claims, damages, or expenses that may arise as a result of the repair work. <br/>
                                <strong>Warranty:</strong> JMA Electronics provides a 30 days labor warranty if same defects detailed in Repair Details above occur. <br/>
                                <strong>Out of Warranty:</strong> The warranty does not cover any defects in the repair work that arise due to misuse or neglect of the appliance or any issues reported that is unrelated to the defect as detailed in the Repair Details Above. <br/>
                                <strong>Void of Warranty:</strong> If the appliance is found to be damaged or not repairable due to misuse or neglect, the warranty will be voided and the customer will be responsible for all repair costs. <br/>
                                <strong>Storage Fee:</strong> If the customer is unable to retrieve their appliance within 7 days of the repair being completed and being notified by JMA Electornics, a storage fee of Php20 per day will be applied for each unit that the appliance remains in the possession of JMA Electronics. <br/>
                                <strong>Customer Notification:</strong> JMA Electronics will make reasonable efforts to contact the customer to arrange for retrieval of the appliance but is not responsible for any damages or loss that may occur due to the appliance being in storage. <br/>
                                <strong>Release of Units:</strong> The customer is responsible for paying all storage fees in full before the appliance will be released. <br/>
                                <strong>Disposal of Unclaimed Units:</strong> JMA Electronics reserves the right to dispose of the appliance if it remains in storage for 60 days and all storage fees have not been paid. <br/>
                                The customer agrees to these terms and conditions by signing and submitting the job order form.
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-4">
                                <div className="cards">
                                    <table className='table border-0 mb-0'>
                                        <tr>
                                            <td className='border-top-0'>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className='text-center print-border-top'>Customer’s Signature Over Printed Name</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-3">
                                <div className="cards">
                                    <table className='table table-large'>
                                        <tr>
                                            <td className='border-top-0'>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className='text-center print-border-top'>Date</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <hr className='cut-guide-line' />
                        <div className="row">
                            <div className="col-6 print-border-right pr-5 border-right" style={{fontSize: '0.9rem'}}>
                                <h5 className='text-center' style={{fontSize: '0.9rem', fontWeight: 'bold'}}>Unit Informormation</h5>
                                <small className="float-right">Date: {moment().format('MMM D, YYYY')}</small>
                                <address>
                                    <strong>{service_data.first_name} {service_data.last_name}</strong><br />
                                    {service_data.address_1}<br />
                                    {service_data.address_2 ? <>{service_data.address_2} <br /></> : ''}
                                    {service_data.city}, {service_data.province}<br />
                                    Mobile: {service_data.mobile_no}<br/>
                                    {service_data.email ? 'Email: ' + service_data.email : ''}
                                </address>
                                <b>Service Request No:</b> {service_data.service_no}<br />
                                <b>Techinician:</b> {service_data.technician_first_name ? service_data.technician_first_name + ' ' + service_data.technician_last_name : '---'}<br />
                                <b>Appliance Type:</b> {service_data.appliance_type}<br />
                                <b>Brand:</b> {service_data.brand ? service_data.brand : '---'}<br />
                                <b>Model No:</b> {service_data.model_no ? service_data.model_no : '---'}<br />
                                <b>Serial No:</b> {service_data.serial_no ? service_data.serial_no : '---'}<br />
                                <b>Warranty Status:</b> {helpers.ucwords(service_data.warranty_status)}<br />
                                <b>Complaint:</b> {service_data.complaint}<br />
                                <b>Schedule Type:</b> {service_data.schedule_type ? helpers.ucwords(service_data.schedule_type) : '---'}<br />
                                {
                                    service_data.dealer ?
                                    <><b>Dealer:</b> {service_data.dealer}<br /></> : ''
                                }
                            </div>
                            <div className="col-6 pl-5" style={{fontSize: '0.9rem'}}>
                                <small className='float-right'>Date: {moment().format('MMM D, YYYY')}</small>
                                <h5 className='text-center' style={{fontSize: '0.9rem', fontWeight: 'bold'}}>Customer Claim Stub</h5>
                                <div className="row">
                                    <div className="col-7">
                                            <address>
                                                <strong>{service_data.first_name} {service_data.last_name}</strong><br />
                                                {service_data.address_1}<br />
                                                {service_data.address_2 ? <>{service_data.address_2} <br /></> : ''}
                                                {service_data.city}, {service_data.province}<br />
                                                Mobile: {service_data.mobile_no}<br/>
                                                {service_data.email ? 'Email: ' + service_data.email : ''}
                                            </address>
                                            <b>Service Request No:</b> {service_data.service_no}<br />
                                            <b>Techinician:</b> {service_data.technician_first_name ? service_data.technician_first_name + ' ' + service_data.technician_last_name : '---'}<br />
                                            <b>Appliance Type:</b> {service_data.appliance_type}<br />
                                            <b>Brand:</b> {service_data.brand ? service_data.brand : '---'}<br />
                                            <b>Model No:</b> {service_data.model_no ? service_data.model_no : '---'}<br />
                                            <b>Serial No:</b> {service_data.serial_no ? service_data.serial_no : '---'}<br />
                                            <b>Warranty Status:</b> {helpers.ucwords(service_data.warranty_status)}<br />
                                            <b>Complaint:</b> {service_data.complaint}<br />
                                            <b>Schedule Type:</b> {service_data.schedule_type ? helpers.ucwords(service_data.schedule_type) : '---'}<br />
                                            {
                                                service_data.dealer ?
                                                <><b>Dealer:</b> {service_data.dealer}<br /></> : ''
                                            }
                                    </div>
                                    <div className="col-5">
                                        <div className="card mt-2">
                                            <div className="card-body print-border p-2">
                                                <p className='mb-0 font-weight-bold'>Check Latest Status of Your Repair</p>
                                                <p className='mb-1'>Visit: <a href="https://lmo.me/JMAServiceHubTrack">https://lmo.me/JMAServiceHubTrack</a></p>
                                                <p className='mb-1'>or Scan QR Code Below:</p>
                                                <img className='mb-1' src={images.qrCode} style={{maxWidth: 120}} />
                                                <p className='mb-0'>And enter these details:
                                                    <br/>Last Name: {service_data.last_name}
                                                    <br/>Reference No: {service_data.service_no}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* /.row */}
                        {/* this row will not appear when printing */}
                        <hr className='no-print'/>
                        <div className="row no-print">
                            <div className="col-12">  
                                {action == 'print' ? <button href="print-view" rel="noopener" target="_blank" className="btn btn-primary float-right" onClick={(e) => print()}><i className="fas fa-print" /> Print</button> : <a href="print-view" rel="noopener" target="_blank" className="btn btn-primary float-right" ><i className="fas fa-print" /> Print</a>}

                                
                                <Link className='btn btn-default float-right mr-3' to="/service-request"><i className="fas fa-arrow-left mr-3" />Back</Link>   
                            </div>
                        </div>
                        </div>
                        {/* /.invoice */}
                    </div>{/* /.col */}
                </div>{/* /.row */}
        </section>
        </>
    )
}
 
export default JobOrderInfo;